import { Accordion, AccordionControl, AccordionItem, AccordionPanel, rem, Text } from '@mantine/core';

import classes from './FaqComponent.module.css';

export default function FaqComponent({ faq, ...props }) {
  const items = faq.map((item, index) => (
    <AccordionItem key={item.label} value={'item-' + index}>
      <AccordionControl>
        <Text fz={rem(24)} c="gfdark.9">
          {item.label}
        </Text>
      </AccordionControl>
      <AccordionPanel>
        <Text fz={rem(16)} c="gfdark.5" maw={'80%'}>
          {item.description}
        </Text>
      </AccordionPanel>
    </AccordionItem>
  ));

  return (
    <Accordion
      defaultValue={'item-0'}
      chevronPosition="right"
      variant="contained"
      classNames={classes}
      radius={'xs'}
      mt={'xl'}
      {...props}
    >
      {items}
    </Accordion>
  );
}
